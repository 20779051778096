
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "9b5fd5e2-107a-40c4-9fb5-2a94ea1442d1"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/apps/frontend/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
