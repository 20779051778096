import lodash from 'lodash'
import type { ComputedRef, Ref } from 'vue'

const { mapKeys, mapValues, lowerCase } = lodash

export const ColorSchemes = {
  LIGHT: 'light',
  DARK: 'dark',
  AUTO: 'auto',
  CONTRAST: 'contrast',
} as const

export type ColorSchemeKey = keyof typeof ColorSchemes
export type ColorScheme = (typeof ColorSchemes)[keyof typeof ColorSchemes]

type MaybeRef<T> = Ref<T> | T

export function useAvailableColorSchemes() {
  const { t } = useI18n()

  const { themes } = useAppConfig()

  const preferredDark = usePreferredDark()
  const preferredMode = computed(() => (preferredDark.value ? 'dark' : 'light'))

  type Themes = (typeof themes)[number]['key']

  const modes = computed(() => themes.map(({ key }) => key))

  const available = computed(() => {
    return themes.map(({ key: _key, icon }) => {
      const key = _key.toUpperCase() as keyof typeof ColorSchemes

      const theme = _key === 'auto' ? preferredMode.value : _key

      return {
        key,
        icon,
        theme,
        mode: _key,
        label: t(`theme.${_key}.label`),
        description: t(`theme.${_key}.description`),
      }
    })
  })

  return { modes, available, data: available }
}

export function useColorScheme() {
  const initialValue = 'auto'

  const mode = useLocalStorage('pref:color-scheme', initialValue)

  const theme = useColorMode({
    storageRef: mode,
    attribute: 'data-theme',
    modes: mapKeys(ColorSchemes, lowerCase),
  })

  const activate = (_scheme?: MaybeRef<ColorScheme>) => {
    const scheme = unref(_scheme || theme)

    useHead({
      htmlAttrs: {
        'data-theme': theme,
      },
    })
  }

  const isDarkMode = computed(() => theme.value === 'dark')

  return {
    mode,
    theme,
    colorMode: mode,
    colorScheme: theme,
    isDarkMode,
    activate,
  }
}
