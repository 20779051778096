export const localeCodes = ["en","es"]

export const localeMessages = {
  "en": [{ key: "../i18n/en-US.yaml", load: () => import("../i18n/en-US.yaml" /* webpackChunkName: "lang_en_US_yaml_en_US_yaml" */) }],
  "es": [{ key: "../i18n/es-ES.yaml", load: () => import("../i18n/es-ES.yaml" /* webpackChunkName: "lang_es_ES_yaml_es_ES_yaml" */) }],
}

export const additionalMessages = Object({"en":[],"es":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  const vueI18nOptionsLoader = async (context) => Object({"fallbackLocale":"en","datetimeFormats":Object({"en":Object({"short":Object({"year":"numeric","month":"short","day":"numeric"}),"date":Object({"weekday":"long"})}),"es":Object({"short":Object({"year":"numeric","month":"short","day":"numeric"}),"date":Object({"weekday":"long"})})}),"messages": Object({}),})
  nuxtI18nOptions.vueI18n = await vueI18nOptionsLoader(context)
  nuxtI18nOptions.locales = [Object({"code":"en","file":"en-US.yaml","path":"/vercel/path0/apps/frontend/i18n/en-US.yaml"}),Object({"code":"es","file":"es-ES.yaml","path":"/vercel/path0/apps/frontend/i18n/es-ES.yaml"})]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "i18n"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.onBeforeLanguageSwitch = (() => "")
  nuxtI18nOptions.onLanguageSwitched = (() => null)
  nuxtI18nOptions.types = undefined
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({vueI18n: undefined,locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,onBeforeLanguageSwitch: (() => ""),onLanguageSwitched: (() => null),types: undefined,debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","file":"en-US.yaml","path":"/vercel/path0/apps/frontend/i18n/en-US.yaml"}),Object({"code":"es","file":"es-ES.yaml","path":"/vercel/path0/apps/frontend/i18n/es-ES.yaml"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const isSSR = true
