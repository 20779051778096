import { unref } from 'vue'
import { info } from '~/utils/logging'

export default defineNuxtPlugin(({ $i18n }) => {
  const head = useHead({
    htmlAttrs: {
      lang: $i18n.locale,
    },
  })

  info(`Setting html.lang to ${unref($i18n.locale)}`)

  // onLanguageSwitched called right after a new locale has been set
  $i18n.onLanguageSwitched = (oldLocale: string, newLocale: string) => {
    if (!head) return

    head.patch({
      htmlAttrs: {
        lang: newLocale,
      },
    })

    info(`Updating html.lang to ${newLocale}`)
  }
})
