import revive_payload_client_SiTDxb8wEx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JP9hgCRXAg from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nGSd4CHCQ0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_cw6b5mR1Ls from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_T13t8A7OS9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_o8pbxupr6Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_ziWVlLMKIm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.8_rollup@4.16.2_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_hW51JikXMr from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_nn4non4aJG from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.0.0-beta.25_rollup@4.16.2_vue@3.4.27_typescript@5.4.5_/node_modules/@vueuse/motion/dist/runtime/templates/motion.js";
import plugin_d6tHRHOl06 from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_encoding@0.1.13_next-auth@4.21.1_next@14.2.3_@babel+core@7.21.8_@op_chxniqstm7xooo7nd3u7veqr3u/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import composition_XkwQzSWE32 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.16.2_vue-router@4.3.3_vue@3.4.27_typescript@5.4.5___vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_spwh8yT4yf from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.16.2_vue-router@4.3.3_vue@3.4.27_typescript@5.4.5___vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_ZLoB8qeHuz from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import colorScheme_1gn2XeGnHV from "/vercel/path0/apps/frontend/plugins/colorScheme.ts";
import i18n_VfGcjrvSkj from "/vercel/path0/apps/frontend/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_SiTDxb8wEx,
  unhead_JP9hgCRXAg,
  router_nGSd4CHCQ0,
  debug_cw6b5mR1Ls,
  payload_client_T13t8A7OS9,
  check_outdated_build_client_o8pbxupr6Y,
  plugin_vue3_ziWVlLMKIm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hW51JikXMr,
  motion_nn4non4aJG,
  plugin_d6tHRHOl06,
  composition_XkwQzSWE32,
  i18n_spwh8yT4yf,
  chunk_reload_client_ZLoB8qeHuz,
  colorScheme_1gn2XeGnHV,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk
]