import { default as error6mUUKZiiCbMeta } from "/vercel/path0/apps/frontend/pages/auth/error.vue?macro=true";
import { default as _91date_93k3gTFokDmHMeta } from "/vercel/path0/apps/frontend/pages/daily-word/[date].vue?macro=true";
import { default as indexsHZhETRdLEMeta } from "/vercel/path0/apps/frontend/pages/daily-word/index.ts?macro=true";
import { default as pick23ZhAuoYU8Meta } from "/vercel/path0/apps/frontend/pages/daily-word/pick.vue?macro=true";
import { default as install8gLmQHO7sJMeta } from "/vercel/path0/apps/frontend/pages/help/install.vue?macro=true";
import { default as indexjuMqNAP5WEMeta } from "/vercel/path0/apps/frontend/pages/index.ts?macro=true";
import { default as _91name_93Cr75MfktpLMeta } from "/vercel/path0/apps/frontend/pages/playlist/[name].vue?macro=true";
import { default as indexQZHyMEAtRgMeta } from "/vercel/path0/apps/frontend/pages/prayer/index.vue?macro=true";
import { default as submitF20sUbkMokMeta } from "/vercel/path0/apps/frontend/pages/prayer/submit.vue?macro=true";
import { default as thank_45youdPzkJcw4sRMeta } from "/vercel/path0/apps/frontend/pages/prayer/thank-you.vue?macro=true";
import { default as preferencesIG4IAt8lV6Meta } from "/vercel/path0/apps/frontend/pages/preferences.vue?macro=true";
import { default as authDRlKWSvYPYMeta } from "/vercel/path0/apps/frontend/pages/tester/auth.vue?macro=true";
import { default as colorsDG85vlDKM3Meta } from "/vercel/path0/apps/frontend/pages/tester/colors.vue?macro=true";
import { default as date_45inputfqtS7IsPHxMeta } from "/vercel/path0/apps/frontend/pages/tester/date-input.vue?macro=true";
import { default as infinite_45scroll1IrZcqcotXMeta } from "/vercel/path0/apps/frontend/pages/tester/infinite-scroll.vue?macro=true";
import { default as jsonapiLXxClhybV1Meta } from "/vercel/path0/apps/frontend/pages/tester/jsonapi.vue?macro=true";
import { default as notificationsMr4ud0Z5JEMeta } from "/vercel/path0/apps/frontend/pages/tester/notifications.vue?macro=true";
import { default as splash_45switch2AFQSJSjXjMeta } from "/vercel/path0/apps/frontend/pages/tester/splash-switch.vue?macro=true";
import { default as splashOqjkQP8KEYMeta } from "/vercel/path0/apps/frontend/pages/tester/splash.vue?macro=true";
import { default as subscriptionsplXUqRRWrdMeta } from "/vercel/path0/apps/frontend/pages/tester/subscriptions.vue?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0 } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: error6mUUKZiiCbMeta?.name ?? "auth-error___en",
    path: error6mUUKZiiCbMeta?.path ?? "/auth/error",
    meta: error6mUUKZiiCbMeta || {},
    alias: error6mUUKZiiCbMeta?.alias || [],
    redirect: error6mUUKZiiCbMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: error6mUUKZiiCbMeta?.name ?? "auth-error___es",
    path: error6mUUKZiiCbMeta?.path ?? "/es/auth/error",
    meta: error6mUUKZiiCbMeta || {},
    alias: error6mUUKZiiCbMeta?.alias || [],
    redirect: error6mUUKZiiCbMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: _91date_93k3gTFokDmHMeta?.name ?? "daily-word-date___en",
    path: _91date_93k3gTFokDmHMeta?.path ?? "/daily-word/:date()",
    meta: _91date_93k3gTFokDmHMeta || {},
    alias: _91date_93k3gTFokDmHMeta?.alias || [],
    redirect: _91date_93k3gTFokDmHMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/[date].vue").then(m => m.default || m)
  },
  {
    name: _91date_93k3gTFokDmHMeta?.name ?? "daily-word-date___es",
    path: _91date_93k3gTFokDmHMeta?.path ?? "/es/daily-word/:date()",
    meta: _91date_93k3gTFokDmHMeta || {},
    alias: _91date_93k3gTFokDmHMeta?.alias || [],
    redirect: _91date_93k3gTFokDmHMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/[date].vue").then(m => m.default || m)
  },
  {
    name: indexsHZhETRdLEMeta?.name ?? "daily-word___en",
    path: indexsHZhETRdLEMeta?.path ?? "/daily-word",
    meta: indexsHZhETRdLEMeta || {},
    alias: indexsHZhETRdLEMeta?.alias || [],
    redirect: indexsHZhETRdLEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/index.ts").then(m => m.default || m)
  },
  {
    name: indexsHZhETRdLEMeta?.name ?? "daily-word___es",
    path: indexsHZhETRdLEMeta?.path ?? "/es/daily-word",
    meta: indexsHZhETRdLEMeta || {},
    alias: indexsHZhETRdLEMeta?.alias || [],
    redirect: indexsHZhETRdLEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/index.ts").then(m => m.default || m)
  },
  {
    name: pick23ZhAuoYU8Meta?.name ?? "daily-word-pick___en",
    path: pick23ZhAuoYU8Meta?.path ?? "/daily-word/pick",
    meta: pick23ZhAuoYU8Meta || {},
    alias: pick23ZhAuoYU8Meta?.alias || [],
    redirect: pick23ZhAuoYU8Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/pick.vue").then(m => m.default || m)
  },
  {
    name: pick23ZhAuoYU8Meta?.name ?? "daily-word-pick___es",
    path: pick23ZhAuoYU8Meta?.path ?? "/es/daily-word/pick",
    meta: pick23ZhAuoYU8Meta || {},
    alias: pick23ZhAuoYU8Meta?.alias || [],
    redirect: pick23ZhAuoYU8Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/daily-word/pick.vue").then(m => m.default || m)
  },
  {
    name: install8gLmQHO7sJMeta?.name ?? "help-install___en",
    path: install8gLmQHO7sJMeta?.path ?? "/help/install",
    meta: install8gLmQHO7sJMeta || {},
    alias: install8gLmQHO7sJMeta?.alias || [],
    redirect: install8gLmQHO7sJMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/help/install.vue").then(m => m.default || m)
  },
  {
    name: install8gLmQHO7sJMeta?.name ?? "help-install___es",
    path: install8gLmQHO7sJMeta?.path ?? "/es/help/install",
    meta: install8gLmQHO7sJMeta || {},
    alias: install8gLmQHO7sJMeta?.alias || [],
    redirect: install8gLmQHO7sJMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/help/install.vue").then(m => m.default || m)
  },
  {
    name: indexjuMqNAP5WEMeta?.name ?? "index___en",
    path: indexjuMqNAP5WEMeta?.path ?? "/",
    meta: indexjuMqNAP5WEMeta || {},
    alias: indexjuMqNAP5WEMeta?.alias || [],
    redirect: indexjuMqNAP5WEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/index.ts").then(m => m.default || m)
  },
  {
    name: indexjuMqNAP5WEMeta?.name ?? "index___es",
    path: indexjuMqNAP5WEMeta?.path ?? "/es",
    meta: indexjuMqNAP5WEMeta || {},
    alias: indexjuMqNAP5WEMeta?.alias || [],
    redirect: indexjuMqNAP5WEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/index.ts").then(m => m.default || m)
  },
  {
    name: _91name_93Cr75MfktpLMeta?.name ?? "playlist-name___en",
    path: _91name_93Cr75MfktpLMeta?.path ?? "/playlist/:name()",
    meta: _91name_93Cr75MfktpLMeta || {},
    alias: _91name_93Cr75MfktpLMeta?.alias || [],
    redirect: _91name_93Cr75MfktpLMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/playlist/[name].vue").then(m => m.default || m)
  },
  {
    name: _91name_93Cr75MfktpLMeta?.name ?? "playlist-name___es",
    path: _91name_93Cr75MfktpLMeta?.path ?? "/es/playlist/:name()",
    meta: _91name_93Cr75MfktpLMeta || {},
    alias: _91name_93Cr75MfktpLMeta?.alias || [],
    redirect: _91name_93Cr75MfktpLMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/playlist/[name].vue").then(m => m.default || m)
  },
  {
    name: indexQZHyMEAtRgMeta?.name ?? "prayer___en",
    path: indexQZHyMEAtRgMeta?.path ?? "/prayer",
    meta: indexQZHyMEAtRgMeta || {},
    alias: indexQZHyMEAtRgMeta?.alias || [],
    redirect: indexQZHyMEAtRgMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/index.vue").then(m => m.default || m)
  },
  {
    name: indexQZHyMEAtRgMeta?.name ?? "prayer___es",
    path: indexQZHyMEAtRgMeta?.path ?? "/es/prayer",
    meta: indexQZHyMEAtRgMeta || {},
    alias: indexQZHyMEAtRgMeta?.alias || [],
    redirect: indexQZHyMEAtRgMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/index.vue").then(m => m.default || m)
  },
  {
    name: submitF20sUbkMokMeta?.name ?? "prayer-submit___en",
    path: submitF20sUbkMokMeta?.path ?? "/prayer/submit",
    meta: submitF20sUbkMokMeta || {},
    alias: submitF20sUbkMokMeta?.alias || [],
    redirect: submitF20sUbkMokMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/submit.vue").then(m => m.default || m)
  },
  {
    name: submitF20sUbkMokMeta?.name ?? "prayer-submit___es",
    path: submitF20sUbkMokMeta?.path ?? "/es/prayer/submit",
    meta: submitF20sUbkMokMeta || {},
    alias: submitF20sUbkMokMeta?.alias || [],
    redirect: submitF20sUbkMokMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/submit.vue").then(m => m.default || m)
  },
  {
    name: thank_45youdPzkJcw4sRMeta?.name ?? "prayer-thank-you___en",
    path: thank_45youdPzkJcw4sRMeta?.path ?? "/prayer/thank-you",
    meta: thank_45youdPzkJcw4sRMeta || {},
    alias: thank_45youdPzkJcw4sRMeta?.alias || [],
    redirect: thank_45youdPzkJcw4sRMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youdPzkJcw4sRMeta?.name ?? "prayer-thank-you___es",
    path: thank_45youdPzkJcw4sRMeta?.path ?? "/es/prayer/thank-you",
    meta: thank_45youdPzkJcw4sRMeta || {},
    alias: thank_45youdPzkJcw4sRMeta?.alias || [],
    redirect: thank_45youdPzkJcw4sRMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/prayer/thank-you.vue").then(m => m.default || m)
  },
  {
    name: preferencesIG4IAt8lV6Meta?.name ?? "preferences___en",
    path: preferencesIG4IAt8lV6Meta?.path ?? "/preferences",
    meta: preferencesIG4IAt8lV6Meta || {},
    alias: preferencesIG4IAt8lV6Meta?.alias || [],
    redirect: preferencesIG4IAt8lV6Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: preferencesIG4IAt8lV6Meta?.name ?? "preferences___es",
    path: preferencesIG4IAt8lV6Meta?.path ?? "/es/preferences",
    meta: preferencesIG4IAt8lV6Meta || {},
    alias: preferencesIG4IAt8lV6Meta?.alias || [],
    redirect: preferencesIG4IAt8lV6Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: authDRlKWSvYPYMeta?.name ?? "tester-auth___en",
    path: authDRlKWSvYPYMeta?.path ?? "/tester/auth",
    meta: authDRlKWSvYPYMeta || {},
    alias: authDRlKWSvYPYMeta?.alias || [],
    redirect: authDRlKWSvYPYMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/auth.vue").then(m => m.default || m)
  },
  {
    name: authDRlKWSvYPYMeta?.name ?? "tester-auth___es",
    path: authDRlKWSvYPYMeta?.path ?? "/es/tester/auth",
    meta: authDRlKWSvYPYMeta || {},
    alias: authDRlKWSvYPYMeta?.alias || [],
    redirect: authDRlKWSvYPYMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/auth.vue").then(m => m.default || m)
  },
  {
    name: colorsDG85vlDKM3Meta?.name ?? "tester-colors___en",
    path: colorsDG85vlDKM3Meta?.path ?? "/tester/colors",
    meta: colorsDG85vlDKM3Meta || {},
    alias: colorsDG85vlDKM3Meta?.alias || [],
    redirect: colorsDG85vlDKM3Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/colors.vue").then(m => m.default || m)
  },
  {
    name: colorsDG85vlDKM3Meta?.name ?? "tester-colors___es",
    path: colorsDG85vlDKM3Meta?.path ?? "/es/tester/colors",
    meta: colorsDG85vlDKM3Meta || {},
    alias: colorsDG85vlDKM3Meta?.alias || [],
    redirect: colorsDG85vlDKM3Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/colors.vue").then(m => m.default || m)
  },
  {
    name: date_45inputfqtS7IsPHxMeta?.name ?? "tester-date-input___en",
    path: date_45inputfqtS7IsPHxMeta?.path ?? "/tester/date-input",
    meta: date_45inputfqtS7IsPHxMeta || {},
    alias: date_45inputfqtS7IsPHxMeta?.alias || [],
    redirect: date_45inputfqtS7IsPHxMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/date-input.vue").then(m => m.default || m)
  },
  {
    name: date_45inputfqtS7IsPHxMeta?.name ?? "tester-date-input___es",
    path: date_45inputfqtS7IsPHxMeta?.path ?? "/es/tester/date-input",
    meta: date_45inputfqtS7IsPHxMeta || {},
    alias: date_45inputfqtS7IsPHxMeta?.alias || [],
    redirect: date_45inputfqtS7IsPHxMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/date-input.vue").then(m => m.default || m)
  },
  {
    name: infinite_45scroll1IrZcqcotXMeta?.name ?? "tester-infinite-scroll___en",
    path: infinite_45scroll1IrZcqcotXMeta?.path ?? "/tester/infinite-scroll",
    meta: infinite_45scroll1IrZcqcotXMeta || {},
    alias: infinite_45scroll1IrZcqcotXMeta?.alias || [],
    redirect: infinite_45scroll1IrZcqcotXMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/infinite-scroll.vue").then(m => m.default || m)
  },
  {
    name: infinite_45scroll1IrZcqcotXMeta?.name ?? "tester-infinite-scroll___es",
    path: infinite_45scroll1IrZcqcotXMeta?.path ?? "/es/tester/infinite-scroll",
    meta: infinite_45scroll1IrZcqcotXMeta || {},
    alias: infinite_45scroll1IrZcqcotXMeta?.alias || [],
    redirect: infinite_45scroll1IrZcqcotXMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/infinite-scroll.vue").then(m => m.default || m)
  },
  {
    name: jsonapiLXxClhybV1Meta?.name ?? "tester-jsonapi___en",
    path: jsonapiLXxClhybV1Meta?.path ?? "/tester/jsonapi",
    meta: jsonapiLXxClhybV1Meta || {},
    alias: jsonapiLXxClhybV1Meta?.alias || [],
    redirect: jsonapiLXxClhybV1Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/jsonapi.vue").then(m => m.default || m)
  },
  {
    name: jsonapiLXxClhybV1Meta?.name ?? "tester-jsonapi___es",
    path: jsonapiLXxClhybV1Meta?.path ?? "/es/tester/jsonapi",
    meta: jsonapiLXxClhybV1Meta || {},
    alias: jsonapiLXxClhybV1Meta?.alias || [],
    redirect: jsonapiLXxClhybV1Meta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/jsonapi.vue").then(m => m.default || m)
  },
  {
    name: notificationsMr4ud0Z5JEMeta?.name ?? "tester-notifications___en",
    path: notificationsMr4ud0Z5JEMeta?.path ?? "/tester/notifications",
    meta: notificationsMr4ud0Z5JEMeta || {},
    alias: notificationsMr4ud0Z5JEMeta?.alias || [],
    redirect: notificationsMr4ud0Z5JEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsMr4ud0Z5JEMeta?.name ?? "tester-notifications___es",
    path: notificationsMr4ud0Z5JEMeta?.path ?? "/es/tester/notifications",
    meta: notificationsMr4ud0Z5JEMeta || {},
    alias: notificationsMr4ud0Z5JEMeta?.alias || [],
    redirect: notificationsMr4ud0Z5JEMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/notifications.vue").then(m => m.default || m)
  },
  {
    name: splash_45switch2AFQSJSjXjMeta?.name ?? "tester-splash-switch___en",
    path: splash_45switch2AFQSJSjXjMeta?.path ?? "/tester/splash-switch",
    meta: splash_45switch2AFQSJSjXjMeta || {},
    alias: splash_45switch2AFQSJSjXjMeta?.alias || [],
    redirect: splash_45switch2AFQSJSjXjMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/splash-switch.vue").then(m => m.default || m)
  },
  {
    name: splash_45switch2AFQSJSjXjMeta?.name ?? "tester-splash-switch___es",
    path: splash_45switch2AFQSJSjXjMeta?.path ?? "/es/tester/splash-switch",
    meta: splash_45switch2AFQSJSjXjMeta || {},
    alias: splash_45switch2AFQSJSjXjMeta?.alias || [],
    redirect: splash_45switch2AFQSJSjXjMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/splash-switch.vue").then(m => m.default || m)
  },
  {
    name: splashOqjkQP8KEYMeta?.name ?? "tester-splash___en",
    path: splashOqjkQP8KEYMeta?.path ?? "/tester/splash",
    meta: splashOqjkQP8KEYMeta || {},
    alias: splashOqjkQP8KEYMeta?.alias || [],
    redirect: splashOqjkQP8KEYMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/splash.vue").then(m => m.default || m)
  },
  {
    name: splashOqjkQP8KEYMeta?.name ?? "tester-splash___es",
    path: splashOqjkQP8KEYMeta?.path ?? "/es/tester/splash",
    meta: splashOqjkQP8KEYMeta || {},
    alias: splashOqjkQP8KEYMeta?.alias || [],
    redirect: splashOqjkQP8KEYMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/splash.vue").then(m => m.default || m)
  },
  {
    name: subscriptionsplXUqRRWrdMeta?.name ?? "tester-subscriptions___en",
    path: subscriptionsplXUqRRWrdMeta?.path ?? "/tester/subscriptions",
    meta: subscriptionsplXUqRRWrdMeta || {},
    alias: subscriptionsplXUqRRWrdMeta?.alias || [],
    redirect: subscriptionsplXUqRRWrdMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: subscriptionsplXUqRRWrdMeta?.name ?? "tester-subscriptions___es",
    path: subscriptionsplXUqRRWrdMeta?.path ?? "/es/tester/subscriptions",
    meta: subscriptionsplXUqRRWrdMeta || {},
    alias: subscriptionsplXUqRRWrdMeta?.alias || [],
    redirect: subscriptionsplXUqRRWrdMeta?.redirect,
    component: () => import("/vercel/path0/apps/frontend/pages/tester/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.path ?? "/help",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.path ?? "/pray",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.path ?? "/today",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0Meta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4614_462__64unocss_43reset_640_4660_464_axios_641_467IznLGmZCi0
  }
]