import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/pages/runtime/validate.js";
import previous_45path_45global from "/vercel/path0/apps/frontend/middleware/previous-path.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@0.60.4_axios@1.7.2_debug@_56rv5bcfotqhhag7ukumliwbtu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  previous_45path_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "cache-control": () => import("/vercel/path0/apps/frontend/middleware/cache-control.ts"),
  auth: () => import("/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_encoding@0.1.13_next-auth@4.21.1_next@14.2.3_@babel+core@7.21.8_@op_chxniqstm7xooo7nd3u7veqr3u/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}