<template>
  <component :is="ErrorComponent" :="error" :cause="cause" />
</template>

<script lang="ts" setup>
import { Error500, Error403, Error404, Error500Dev } from '#components'

// Deliberately prevent reactive update when error is cleared
// eslint-disable-next-line vue/no-setup-props-destructure
const { error } = defineProps<{
  error: {
    statusCode?: string | number
    statusMessage?: string
    cause?: string
    message?: string
    description?: string
    url?: string
    stack?: string
  }
}>()

// TODO: extract to a separate utility
const stacktrace = (error.stack || '')
  .split('\n')
  .splice(1)
  .map((line) => {
    const text = line
      .replace('webpack:/', '')
      .replace('.vue', '.js') // TODO: Support sourcemap
      .trim()
    return {
      text,
      internal:
        (line.includes('node_modules') && !line.includes('.cache')) ||
        line.includes('internal') ||
        line.includes('new Promise'),
    }
  })
  .map(
    (i) =>
      `<span class="stack${i.internal ? ' internal' : ''}">${i.text}</span>`,
  )
  .join('\n')

// Error page props
const statusCode = Number(error.statusCode || 500)
const is404 = statusCode === 404
const is403 = statusCode === 403

const statusMessage =
  error.statusMessage ?? (is404 ? 'Page Not Found' : 'Internal Server Error')
const description = (error.message || error.toString()).split(':', 2)[0]
const url = error.url

const cause = error.cause || (typeof error.data === 'string' && error.data)

const stack =
  process.dev && !is404
    ? error.description || `<pre>${stacktrace}</pre>`
    : undefined

const ErrorComponent = computed(() => {
  if (is403) return Error403
  if (is404) return Error404
  if (process.dev) return Error500Dev
  return Error500
})

const title = computed(
  () => `${error.statusCode} - ${error.statusMessage} | BeUnity`,
)

useHead({
  title,
})
</script>
