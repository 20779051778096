import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { sentry: _config } = useAppConfig()
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  type Config = (typeof _config)['client']

  const config: Config = {
    ...{
      // Configure this whole part as you need it!

      tracesSampleRate: 0.2, // Change in prod

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://unity.org'],

      replaysSessionSampleRate: 0.2, // Change in prod
      replaysOnErrorSampleRate: 0.2, // Change in prod if necessary
    },
    ..._config.client,
  }

  if ((config as any)?.disable || true) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    ...config,
  })
})
